import Alert from 'react-bootstrap/Alert';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import loadImage from 'blueimp-load-image';
import Row from 'react-bootstrap/Row';
import './styles.css';

export default class Restaurants extends React.Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;
    this.infoContentRef = React.createRef();
    this.infoWindow = null;
    this.inputFileRef = React.createRef();
    this.map = null;
    this.mapRef = React.createRef();
    this.marker = null;
    this.restaurantAutocomplete = null;
    this.textCityRef = React.createRef();
    this.textRestaurantRef = React.createRef();

    let lastRestaurant = undefined;
    let lastMenuImage = undefined; 
    if (typeof(Storage) !== 'undefined') {
      try {
        lastRestaurant = JSON.parse(localStorage.getItem("lastRestaurant"));
        console.log('lastRestaurant is: ', lastRestaurant);
      } catch (e) {
        console.error(e);
      }
      
      try {
        lastMenuImage = JSON.parse(localStorage.getItem("lastMenuImage"));
        console.log('lastMenuImage is: ', lastMenuImage);
      } catch (e) {
        console.error(e);
      }
    }

    this.state = {
      restaurant: null,
      lastMenuImage: lastMenuImage,
      lastRestaurant: lastRestaurant
    };
  }

  saveLastSelections = (canvas) => {
    if (typeof(Storage) === 'undefined') {
      return;
    }

    localStorage.setItem('lastRestaurant', JSON.stringify({
      name: this.state.restaurant.name,
      placeId: this.state.restaurant.place_id,
      vicinity: this.state.restaurant.vicinity
    }));

    localStorage.setItem('lastMenuImage', JSON.stringify({
      dataUrl: canvas.toDataURL(),
      width: canvas.width,
      height: canvas.height
    }));
  }

  loadImageCallback = (canvas) => {
    this.saveLastSelections(canvas);

    this.props.history.push('/menu', {
      menuImage: {
        dataUrl: canvas.toDataURL(),
        width: canvas.width,
        height: canvas.height,
      },
      restaurant: {
        name: this.state.restaurant.name,
        placeId: this.state.restaurant.place_id
      },
    });
  };

  handleImageSelection = (event) => {
    console.log('used file is: ', event.target.files);
    if (event.target.files.length !== 1) {
      console.error('wrong number of files');
      return;
    }

    const file = event.target.files[0];
    if (!file.type.startsWith('image/')) {
      console.error('wrong file type: ', file.type);
      return;
    }

    console.log('history object is: ', this.props.history);

    loadImage(event.target.files[0], this.loadImageCallback,
      {
        canvas: true,
        maxWidth: 600,
        orientation: true,
      }
    );
  };

  onPlaceChanged = () => {
    var place = this.autocomplete.getPlace();
    if (place.geometry) {
      this.map.panTo(place.geometry.location);
      this.map.setZoom(10);
      if (this.marker) {
        this.marker.setMap(null);
      }
    } else {
      this.textCityRef.current.placeholder = 'Enter a city';
    }
  }

  onRestaurantSelected = () => {
    var place = this.restaurantAutocomplete.getPlace();
    if (place.geometry) {
      console.log('selected restaurant is: ', place);
      console.log('selected restaurant location is: ' + place.geometry.location.lat() + ', ' + place.geometry.location.lng());

      if (this.marker) {
        this.marker.setMap(null);
      }

      this.marker = new window.google.maps.Marker({
        map: this.map,
        position: place.geometry.location,
        animation: window.google.maps.Animation.DROP,
      });

      this.setState({
        restaurant: place
      });

      window.google.maps.event.addListener(this.marker, 'click',
        () => this.infoWindow.open(this.map, this.marker));
    } else {
      this.textRestaurantRef.current.placeholder = 'Select Restaurant';
      this.marker.setMap(null);
      this.setState({
        restaurant: null
      });
    }
  }

  initMap = () => {
    if (!this.textCityRef.current) {
      console.log('refs are not inited yet');
      return;
    }

    const google = window.google;

    this.map = new google.maps.Map(this.mapRef.current, {
      zoom: 4,
      fullscreenControl: false,
      center: { lat: 37.1, lng: -95.7 },
      mapTypeControl: false,
      panControl: false,
      zoomControl: true,
      streetViewControl: false
    });

    this.infoWindow = new google.maps.InfoWindow({
      content: this.infoContentRef.current
    });

    this.placesService = new google.maps.places.PlacesService(this.map);

    this.autocomplete = new google.maps.places.Autocomplete(this.textCityRef.current, {
      types: ['(cities)'],
    });

    this.autocomplete.addListener('place_changed', this.onPlaceChanged);

    this.restaurantAutocomplete = new google.maps.places.Autocomplete(this.textRestaurantRef.current, {
      strictBounds: true,
      types: ['establishment']
    });
    this.restaurantAutocomplete.bindTo('bounds', this.map);
    this.restaurantAutocomplete.addListener('place_changed', this.onRestaurantSelected);
    this.restaurantAutocomplete.setFields(['geometry', 'icon', 'name', 'place_id', 'types', 'vicinity'])
  }

  moveMapToPosition = (lat, lng) => {
    var pos = {
      lat: lat,
      lng: lng
    };

    this.map.setZoom(15);
    this.map.setCenter(pos);
  };

  componentDidMount() {
    this.initMap();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position =>
        this.moveMapToPosition(position.coords.latitude, position.coords.longitude),
      (error) => console.error(error));
    }
  }

  useSavedSearchResults = () => {
    console.log('useSavedSearchResults');
    this.props.history.push('/menu', {
      fromLocalStorage: true,
      menuImage: {
        dataUrl: this.state.lastMenuImage.dataUrl,
        width: this.state.lastMenuImage.width,
        height: this.state.lastMenuImage.height,
      },
      restaurant: {
        name: this.state.lastRestaurant.name,
        placeId: this.state.lastRestaurant.placeId
      },
    });
  };

  render() {
    return (
      <div>
        <div style={{ display: 'none' }}>
          <div id="info-content" ref={this.infoContentRef}>
            <Container>
              <Row>
                <Col><b>{this.state.restaurant && this.state.restaurant.name}</b></Col>
              </Row>
              <Row>
                <Col><i>{this.state.restaurant && this.state.restaurant.vicinity}</i></Col>
              </Row>
              {this.state.restaurant && !this.state.restaurant.types.includes("food") && !this.state.restaurant.types.includes("cafe") &&
                <Row>
                  <Col>
                    <Alert variant="danger">Not a Restaurant</Alert>
                  </Col>
                </Row>
              }
              <Row>
                <Col>
                  <Button onClick={() => this.inputFileRef.current.click()} variant="outline-primary">Use the Picture of the Menu to Lookup for Foods</Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <input style={{ display: 'none' }} type="file"
          accept="image/*"
          onChange={this.handleImageSelection}
          ref={this.inputFileRef} />
        <Container>
          <Row>
            <Col>
              {this.state.lastRestaurant &&
                <p onClick={this.useSavedSearchResults}>last visited restaurant was <b><i>{this.state.lastRestaurant.name}</i></b> at <b><i>{this.state.lastRestaurant.vicinity}</i></b></p>
              }
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <input type="text" className="form-control" id="textRestaurant" placeholder="Enter the restaurant" ref={this.textRestaurantRef}></input>
            </Col>
            <Col xs={12} md={6}>
              <input type="text" className="form-control" id="textCity" placeholder="Enter the city" ref={this.textCityRef}></input>
            </Col>
          </Row>
          <Row>
            <Col>
              <div id="map" ref={this.mapRef} ></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}