import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

export default class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // Whether the display name should be displayed in Sign Up page.
          requireDisplayName: true,
          signInMethod: 'emailLink',
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: this.goBack
      }
    }
  }

  goBack = (authResult, redirectUrl) => {
    // If a user signed in with email link, ?showPromo=1234 can be obtained from
    // window.location.href.
    // ...
    console.log('signInSuccessWithAuthResult: ', authResult, redirectUrl);
    this.props.history.goBack();
    return false;
  };

  render() {
    return (
      <div>
        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    );
  }
}