import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Redirect } from 'react-router';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import './styles.css';

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    console.log('received params is: ', this.props);
    this.canvasRef = React.createRef();

    let textAnnotations;
    if (this.props.location.state.fromLocalStorage) {
      console.log('passed data is coming from localStorage');
      try {
        textAnnotations = JSON.parse(localStorage.getItem('lastTextAnnotations'));
      } catch (e) {
        console.log('could not use lastTextAnnotations');
      }
    }

    if (!textAnnotations) {
      console.log('resetting textAnnotations');
      textAnnotations = [];
    }

    this.state = {
      error: undefined,
      foodName: '',
      ocrInProgress: false,
      textAnnotations: textAnnotations
    };
  }

  renderAllBoxes = () => {
    const ctx = this.canvasRef.current.getContext("2d");
    this.state.textAnnotations.forEach(ta =>
      this.renderBoxForTextAnnotation(ctx, ta)
    );
  };

  componentDidMount() {
    this.buildCanvasFromImageDataUrl()
      .then(this.ocrPicture)
      .then(this.renderAllBoxes);
  }

  renderBoxForTextAnnotation = (ctx, ta) => {
    const vertices = ta.boundingPoly.vertices;

    ctx.beginPath();
    ctx.lineWidth = "1";
    ctx.fillStyle = 'black';
    const height = vertices[3].y - vertices[0].y;
    ctx.shadowColor = 'white';
    ctx.shadowBlur = 8;
    ctx.fillRect(vertices[0].x, vertices[0].y, vertices[2].x - vertices[0].x, height);

    ctx.font = height + 'px Georgia';
    ctx.fillStyle = (ta.selected ? 'white' : 'gray');
    ctx.shadowColor = (ta.selected ? 'blue' : 'gray');
    ctx.shadowBlur = (ta.selected ? 10 : 2);
    ctx.textBaseline = 'hanging';
    ctx.fillText(ta.description, vertices[0].x, vertices[0].y, vertices[2].x - vertices[0].x);
  };

  findMatchingRectangles = (x, y) => {
    console.log('findMatchingRectangles(', x, ', ', y, '), checking ', this.state.textAnnotations.length, ' annotations');
    const rectangles = this.state.textAnnotations
      .filter(ta => !ta.selected)
      .filter(ta => ta.boundingPoly.vertices[0].x <= x && x <= ta.boundingPoly.vertices[2].x &&
        ta.boundingPoly.vertices[0].y <= y && y <= ta.boundingPoly.vertices[3].y);

    if (rectangles.length === 0) {
      return;
    }

    const ctx = this.canvasRef.current.getContext("2d");
    const tapped = rectangles[0];
    tapped.selected = true;
    this.renderBoxForTextAnnotation(ctx, tapped);
    this.setState((curState) => {
      return {
        foodName: curState.foodName ? curState.foodName + ' ' + tapped.description : tapped.description
      }
    });
  };

  removeGarbageTextAnnotations = (response) => {
    console.log('received response from OCR server');
    if (response.responses && response.responses.length > 0 && response.responses[0].textAnnotations) {
      let ret = response.responses[0].textAnnotations
        .filter(ta => !ta.locale && ta.boundingPoly && ta.boundingPoly.vertices && ta.boundingPoly.vertices.length === 4);
      return ret;
    } else {
      return [];
    }
  }

  ocrPicture = () => {
    if (this.state.textAnnotations.length > 0) {
      console.log('ocr is not required');
      return;
    }

    this.setState({
      ocrInProgress: true,
    });

    return fetch('https://vision.googleapis.com/v1/images:annotate?key=AIzaSyDHQANj2Y3f--NSyRlHuWQWh-Jta3W9ns8', {
      method: 'POST',
      body: JSON.stringify(
        {
          requests: [{
            image: { content: this.props.location.state.menuImage.dataUrl.replace(/data:image\/.+;base64,/g, '') },
            features: [{ type: "TEXT_DETECTION" }]
          }]
        }
      )
    })
      .then(response => response.json())
      .then(this.removeGarbageTextAnnotations)
      .then(cleaned => {
        this.setState({
          ocrInProgress: false
        });

        if (typeof (Storage) !== 'undefined') {
          localStorage.setItem("lastTextAnnotations", JSON.stringify(cleaned));
        }

        return new Promise(resolve => {
          this.setState({
            textAnnotations: cleaned,
          }, resolve())
        });
      })
      .catch(error => this.setState({ ocrInProgress: false, error: error }));
  };

  buildCanvasFromImageDataUrl = () => {
    this.canvasRef.current.width = this.props.location.state.menuImage.width;
    this.canvasRef.current.height = this.props.location.state.menuImage.height;
    const ctx = this.canvasRef.current.getContext('2d');

    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        resolve();
      };
      img.src = this.props.location.state.menuImage.dataUrl;
    });
  };

  resetSelections = () => {
    this.state.textAnnotations.forEach(ta => ta.selected = false);

    this.setState({ foodName: '' });

    this.buildCanvasFromImageDataUrl()
      .then(this.renderAllBoxes);
  }

  textOnChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleClickOnCanvas = (event) => {
    this.findMatchingRectangles(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
  };

  showResults = () => {
    window.location = `https://www.google.com/search?tbm=isch&q=${this.state.foodName}+in+${this.props.location.state.restaurant.name}`;
  };

  render() {
    if (!this.props.location.state || !this.props.location.state.restaurant) {
      return (<Redirect to="/" />);
    }

    return (
      <Container style={{ background: 'lavender' }}>
        {this.state.error &&
          <Row><Col><Alert variant='danger'>{this.state.error}</Alert></Col></Row>
        }
        <Row>
          <Col>
            {this.state.ocrInProgress &&
              <Spinner animation="grow" variant="primary" />
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="input-group" style={{ verticalAlign: 'middle' }}>
              <input
                className="form-control"
                name="foodName"
                onChange={this.textOnChange}
                placeholder="tap on the boxes to select text..."
                type="text"
                value={this.state.foodName} />
              <div className="input-group-append">
                <Button disabled={!this.state.foodName} onClick={this.showResults}>Search</Button>
                <Button disabled={!this.state.foodName}
                  onClick={this.resetSelections} variant='link'>Clear</Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ alignContent: 'center', overflow: 'auto', overflowY: 'auto', textAlign: 'center', paddingTop: 10 }}>
            <canvas id="newCanvas"
              onClick={this.handleClickOnCanvas}
              ref={this.canvasRef} />
          </Col>
        </Row>
      </Container >
    );
  }
}