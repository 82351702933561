import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import firebase from 'firebase';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Menu from './components/Menu';
import Restaurants from './components/Restaurants';
import Row from 'react-bootstrap/Row';
import SignIn from './components/SignIn';

const config = {
  apiKey: "AIzaSyD9dEdfBmvk0YHiViVCz6kOTR97Sc6C61U",
  authDomain: "test-authenticate-25b99.firebaseapp.com",
  projectId: "test-authenticate-25b99",
};

firebase.initializeApp(config);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signedInUser: null
    };

    this.uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // Whether the display name should be displayed in Sign Up page.
          requireDisplayName: true,
          signInMethod: 'emailLink',
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          // If a user signed in with email link, ?showPromo=1234 can be obtained from
          // window.location.href.
          // ...
          console.log('signInSuccessWithAuthResult: ', authResult, redirectUrl);
          return false;
        }
      }
    }
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => this.setState({
        isSignedIn: !!user,
        signedInUser: user
      })
    );
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  signOut = () => {
    firebase.auth().signOut();
  };

  render() {
    console.log('location is: ', this.props.location);
    return (
      <Router>
        <Container className="mt-1">
          <Row>
            <Col><Link to="/">Home</Link></Col>
            <Col style={{ textAlign: 'center' }} >{this.state.signedInUser && this.state.signedInUser.displayName && <p>Hi {this.state.signedInUser.displayName}</p>}</Col>
            <Col className="float-right">
              <div className="float-right">
                {!this.state.signedInUser &&
                  <Link to="/signin">
                    <Button variant="primary">Sign In</Button>
                  </Link>
                }
                {this.state.signedInUser &&
                  <Button variant="danger" onClick={this.signOut}>Sign Out</Button>
                }
                <p>{this.props.location && this.props.location.pathname}</p>
              </div>
            </Col>
          </Row>

          <Route path="/" exact component={Restaurants} />
          <Route path="/menu" component={Menu} />
          <Route path="/signin/" component={SignIn} />
        </Container>
      </Router>
    );
  }
}

export default App;
